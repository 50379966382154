"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
class SleepcalculatorCtrl {
    constructor($scope, $timeout, ModalServiceFactory, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
    }
    $onInit() { }
}
SleepcalculatorCtrl.$inject = ['$scope', '$timeout', 'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameSleepcalculatorCtrl', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: SleepcalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('sleepcalculator/');
    }]);
